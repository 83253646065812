import React, { FunctionComponent, useMemo } from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa';
import SelectInputDesign, { SelectOption } from '../../../../design-components/select/select';
import { useTranslation } from 'react-i18next';
import { CustomEntitiesTypes } from '../../../../../Pages/CustomEntities/helpers/custom-entities.helper';
import { connect } from 'react-redux';
import { updateSidebarEntityType } from '../../../../../../store/action-creators/content-sidebar-tags';

type Properties = {
	updateEntityTypeInRedux: (domain: string) => void;
	entityType: string;
	isSportDomainYN: boolean;
};

const EntityTypeSelect: FunctionComponent<Properties> = ({ updateEntityTypeInRedux, entityType, isSportDomainYN }) => {
	const { t } = useTranslation();

	const sidebarCustomEntitiesTypeSelectOptions = useMemo(
		() => [
			{ name: t('person'), value: CustomEntitiesTypes.PERSON },
			{ name: t('organization'), value: CustomEntitiesTypes.ORGANIZATION },
			{ name: t('place'), value: CustomEntitiesTypes.PLACE },
		],
		[],
	);

	if (isSportDomainYN) return null;

	const onEntityTypeChange = (option: SelectOption) => {
		updateEntityTypeInRedux(option.value ? option.value.toString() : '');
	};

	return (
		<Row>
			<Col>
				<FormGroup>
					<SelectInputDesign
						invokeFunc={onEntityTypeChange}
						fieldId={DATA_QA_ATTRIBUTES.ENTITY_TYPE_SELECT}
						labelText={!isSportDomainYN ? t('entity_type') : undefined}
						placeholderText={t('select')}
						options={sidebarCustomEntitiesTypeSelectOptions}
						selectedValue={entityType || undefined}
					/>
				</FormGroup>
			</Col>
		</Row>
	);
};

function mapStateToProps(state: any) {
	return {
		entityType: (state.contentSidebar.tags && state.contentSidebar.tags.entityType) || '',
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		updateEntityTypeInRedux: (entityType: string) => dispatch(updateSidebarEntityType(entityType)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(EntityTypeSelect);
