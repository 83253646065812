import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { ICustomEntitiesReduxStore } from '../../../../../../store/reducers/content-sidebar-tags.reducer';
import CustomEntitiesSelect from '../custom-entities-select/custom-entities-select';
import { generateCustomConnectionsInfoProps } from '../../../../../../global-helpers/sidebar.helpers';

type Properties = {
	addedCustomEntities: ICustomEntitiesReduxStore;
};

const CustomEntitiesConnections: FunctionComponent<Properties> = ({ addedCustomEntities }) => {
	const dataForRenderAsOneType = Object.keys(addedCustomEntities).map((entityType) => {
		const allDataByEntityType = addedCustomEntities[entityType];
		return Object.values(allDataByEntityType);
	});

	return dataForRenderAsOneType
		.filter((entityTypeData) => entityTypeData.length !== 0)
		.map((entityTypeData) => (
			<CustomEntitiesSelect isSportDomainYN={false} customSelectValue={generateCustomConnectionsInfoProps(entityTypeData)} />
		));
};

function mapStateToProps(state: any) {
	return {
		addedCustomEntities: (state.contentSidebar.tags && state.contentSidebar.tags.customEntities) || {},
	};
}

export default connect(mapStateToProps)(CustomEntitiesConnections);
