import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import SportsTypesModel from '../../../../../../models/v2/sports-types/sports-types.model';
import { SportTypes } from '../../../../../../constants/sport.types';
import SportsConnectionsSelect from '../sports-connections/sports-connections';
import { extractRelatedPropertiesNameByUrl, filterReduxRelatedProperties } from '../../helpers/utils';
import { entityTypesWithoutSeason } from '../../helpers/football-connection-select.helper';
import Related from '../../../../../../models/related/Related';
import FootballConnectionsSelect from '../football-connections/football-connections';
import TournamentSelect from '../football-connections/tournament-select';
import { isFootballSelected } from '../../../../../../global-helpers/sidebar.helpers';

type Properties = {
	sports: SportsTypesModel[];
	selectedSport: SportsTypesModel | null;
	reduxSportConnections: Related[] | null;
	shouldDisplayTournamentSelect: boolean;
};

const SportSection: FunctionComponent<Properties> = ({
	sports,
	selectedSport,
	reduxSportConnections,
	shouldDisplayTournamentSelect = true,
}) => {
	const isFootballSelectedYN = isFootballSelected(selectedSport);
	const filteredDataForSelectedSport = selectedSport
		? filterReduxRelatedProperties(selectedSport, reduxSportConnections, entityTypesWithoutSeason)
		: null;
	const { t } = useTranslation();

	return (
		<>
			{selectedSport && selectedSport.sport !== SportTypes.FOOTBALL && (
				<SportsConnectionsSelect selectedSport={selectedSport} allowMoreEntityTypes filteredData={filteredDataForSelectedSport} />
			)}
			{isFootballSelectedYN && <FootballConnectionsSelect t={t} selectedSport={selectedSport!!} />}
			{isFootballSelectedYN && shouldDisplayTournamentSelect && <TournamentSelect t={t} selectedSport={selectedSport!!} />}

			{sports
				.filter((sportEl) => (selectedSport ? sportEl.sport !== selectedSport.sport : true))
				.map((sportEl) => {
					const filteredData = filterReduxRelatedProperties(sportEl, reduxSportConnections, entityTypesWithoutSeason);
					if (!filteredData || filteredData.filtered.length === 0) return null;
					if (sportEl.sport === SportTypes.FOOTBALL) return <FootballConnectionsSelect t={t} selectedSport={sportEl} />;
					return <SportsConnectionsSelect selectedSport={sportEl} allowMoreEntityTypes filteredData={filteredData} />;
				})}
		</>
	);
};

function mapStateToProps(state: any) {
	const nestedReduxPropertyName = extractRelatedPropertiesNameByUrl();

	return {
		reduxSportConnections: (nestedReduxPropertyName && state.tempSportsRelated[nestedReduxPropertyName]) || [],
		sports: state.sports.sportsTypes,
	};
}

export default connect(mapStateToProps)(SportSection);
