import React, { FunctionComponent, useEffect, useState } from 'react';
import TagsSelect from './subcomponents/tags-select/tags-select';
import SuggestedEntities from './subcomponents/suggested-entities/suggested-entities.component';
import { clearSuggestedEntities } from '../../../../store/action-creators/suggested-entities';
import { connect } from 'react-redux';
import './tags.component.scss';
import SportsTypesModel from '../../../../models/v2/sports-types/sports-types.model';
import DomainSelect from './subcomponents/domain-select/domain-select';
import EntityTypeSelect from './subcomponents/entity-type-select/entity-type-select';
import { isFootballSelected, isSportDomain } from '../../../../global-helpers/sidebar.helpers';
import SportSection from './subcomponents/sport-section/sport-section';
import CustomEntitiesSelect from './subcomponents/custom-entities-select/custom-entities-select';
import CustomEntitiesConnections from './subcomponents/custom-entities-connections/custom-entities-connections';
import SportSelect from './subcomponents/sport-select/sport-select';
import EventsContainer from './subcomponents/events/events';
import { useTranslation } from 'react-i18next';
import TournamentSelect from './subcomponents/football-connections/tournament-select';
import { SportTypes } from '../../../../constants/sport.types';
import { extractRelatedPropertiesNameByUrl, filterReduxRelatedProperties } from './helpers/utils';
import { relatedConstants } from '../../../../constants/related.constants';

type Properties = {
	clearSuggestedEntities: Function;
	sports: SportsTypesModel[];
	isSportDomainYN: boolean;
	footballAvailableAsSport: SportsTypesModel | null;
	isFootballTournamentAddedYN: boolean;
};

const SidebarTags: FunctionComponent<Properties> = ({
	clearSuggestedEntities,
	sports,
	isSportDomainYN,
	footballAvailableAsSport,
	isFootballTournamentAddedYN,
}) => {
	const { t } = useTranslation();
	const [selectedSport, setSelectedSport] = useState<SportsTypesModel | null>(sports[0]);
	const shouldDisplayTournamentSelectInTop = isSportDomainYN && isFootballSelected(selectedSport);

	useEffect(() => {
		clearSuggestedEntities();
	}, []);

	return (
		<div>
			<TagsSelect />
			<DomainSelect />
			<EntityTypeSelect isSportDomainYN={isSportDomainYN} />
			<CustomEntitiesSelect isSportDomainYN={isSportDomainYN} />
			<SportSelect selectedSport={isSportDomainYN ? selectedSport : null} availableSports={sports} setSelectedSport={setSelectedSport} />
			<SportSection
				selectedSport={isSportDomainYN ? selectedSport : null}
				shouldDisplayTournamentSelect={shouldDisplayTournamentSelectInTop}
			/>
			<CustomEntitiesConnections />
			{isSportDomainYN && selectedSport && <SuggestedEntities selectedSport={selectedSport} t={t} />}
			{!shouldDisplayTournamentSelectInTop && isFootballTournamentAddedYN && footballAvailableAsSport && (
				<TournamentSelect t={t} selectedSport={footballAvailableAsSport} />
			)}
			<EventsContainer selectedSport={isSportDomainYN ? selectedSport : null} />
		</div>
	);
};

function mapStateToProps(state: any) {
	const sidebarDomain = (state.contentSidebar.tags && state.contentSidebar.tags.domain) || null;
	const nestedReduxPropertyName = extractRelatedPropertiesNameByUrl();
	const reduxSportConnections = nestedReduxPropertyName && state.tempSportsRelated[nestedReduxPropertyName];
	const sports = state.sports.sportsTypes;
	const footballAvailableAsSport = sports.find((sport: SportsTypesModel) => sport.sport === SportTypes.FOOTBALL);
	const filteredTournamentsData = filterReduxRelatedProperties(footballAvailableAsSport, reduxSportConnections, [
		relatedConstants.types.season,
	]);

	return {
		sports,
		footballAvailableAsSport,
		isSportDomainYN: isSportDomain(sidebarDomain),
		isFootballTournamentAddedYN:
			filteredTournamentsData && filteredTournamentsData.filtered && filteredTournamentsData.filtered.length > 0 ? true : false, // this component is reused from many places and I cannot change it without breaking current functionality
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		clearSuggestedEntities: () => dispatch(clearSuggestedEntities()),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarTags);
