import 'reflect-metadata';
import { AnyT, jsonMember, jsonObject } from 'typedjson';
import AssetsModel from './assets.model';
import Country from '../Country/Country.model';

@jsonObject
export default class SportsEntityModel {
	@jsonMember(String)
	public id: string = '';
	@jsonMember(String)
	public name: string = '';
	@jsonMember(String)
	public translated_name: string | null = null;
	@jsonMember(String)
	public sport: string = '';
	@jsonMember(String)
	public birthdate: string | null = null;
	@jsonMember(String)
	public gender: string | null = null;
	@jsonMember(AnyT)
	public competition_ids: any;
	@jsonMember(String)
	public country_id: string | null = null;
	@jsonMember(Country)
	public country: Country | null = null;
	@jsonMember(String)
	public entity_type: string = '';
	@jsonMember(String)
	public type: string | null = null;
	@jsonMember(AssetsModel)
	public display_asset: AssetsModel = {} as AssetsModel;
	@jsonMember(String)
	public slug: string | null = null;
}
