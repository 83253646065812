import debounce from 'lodash.debounce';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { ActionMeta } from 'react-select/src/types';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { relatedConstants } from '../../../../../../constants/related.constants';
import { SelectAction, SelectActionValue } from '../../../../../../constants/select';
import Related from '../../../../../../models/related/Related';
import { modelFootballConnectionToFootballConnectionResponse } from '../../../../../../models/v2/football-connection/response-football-connection.mapper';
import HttpService from '../../../../../../services/rest/HttpService';
import { clearSuggestedEntities, removeSuggestedEntitiesByOrigin } from '../../../../../../store/action-creators/suggested-entities';
import { store } from '../../../../../../store/store';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa';
import {
	entityTypesWithoutSeason,
	footballConnectionResponseToOptions,
	footballConnectionResponseToRelatedModel,
	footballDataTypesForRequest,
	remapReduxSportsConnectionPropertyToFootballConnectionResponseModel,
} from '../../helpers/football-connection-select.helper';
import {
	extractActionForUpdatingSportsConnections,
	extractRelatedPropertiesNameByUrl,
	filterReduxRelatedProperties,
	generateCustomOptionWithImage,
} from '../../helpers/utils';
import { sportsConnectionColorStyles } from '../../constants/styles';
import SportsTypesModel from '../../../../../../models/v2/sports-types/sports-types.model';
import { SportCustomOption } from '../../../../../../global-helpers/sidebar.helpers';

type Properties = {
	t: any;
	reduxSportConnections: Related[] | null;
	updateSportsConnection: Function;
	clearSuggestedEntities: Function;
	removeSuggestedEntitiesByOrigin: Function;
	selectedSport: SportsTypesModel;
};

const FootballConnectionsSelect: FunctionComponent<Properties> = ({
	t,
	reduxSportConnections,
	updateSportsConnection,
	clearSuggestedEntities,
	removeSuggestedEntitiesByOrigin,
	selectedSport,
}) => {
	const filteredDataRedux = filterReduxRelatedProperties(selectedSport, reduxSportConnections, entityTypesWithoutSeason);
	const filteredFootballDataAsModels =
		remapReduxSportsConnectionPropertyToFootballConnectionResponseModel(filteredDataRedux && filteredDataRedux.filtered) || [];

	const modifyFootballConnections = (footballConnectionSelection: any, action: ActionMeta) => {
		if (footballConnectionSelection && footballConnectionSelection.length > 0) {
			const selectedFootballConnections: Related[] = footballConnectionSelection.map(({ data }: any) =>
				footballConnectionResponseToRelatedModel(data, selectedSport),
			);

			const dataForRedux =
				filteredDataRedux && filteredDataRedux.rest.length > 0
					? [...selectedFootballConnections, ...filteredDataRedux.rest]
					: [...selectedFootballConnections];

			updateSportsConnection(dataForRedux);

			if (action.action === SelectActionValue.REMOVE) {
				const actionData = (action as SelectAction).removedValue.data;
				if (actionData.id && actionData.entity_type === relatedConstants.types.team) {
					removeSuggestedEntitiesByOrigin(actionData.id);
				}
			}
		} else {
			updateSportsConnection([]);
			clearSuggestedEntities();
		}
	};

	const loadFootballConnectionsOnType = (input: string, callback: any) => {
		if (input.length > 2) {
			const language = store.getState().project.currentProject.language;

			HttpService.getFootballData(input, language, footballDataTypesForRequest).then((response: any) => {
				const fetchedFootballData: Array<any> = response.data;
				const remappedFootballData = fetchedFootballData.map((el) => modelFootballConnectionToFootballConnectionResponse(el));
				const loadedFootballConnectionsAsOptions = footballConnectionResponseToOptions(remappedFootballData);
				callback(loadedFootballConnectionsAsOptions);
			});
		}
	};

	return (
		<Row>
			<Col>
				<FormGroup>
					<Label htmlFor={DATA_QA_ATTRIBUTES.FOOTBALL_CONNECTION_SELECT}>{t('football_connections')}</Label>
					<AsyncSelect
						id={DATA_QA_ATTRIBUTES.FOOTBALL_CONNECTION_SELECT}
						className='custom-entities-select-sidebar'
						styles={sportsConnectionColorStyles}
						isMulti={true}
						isClearable={true}
						placeholder={t('select')}
						noOptionsMessage={(inputValue) => inputValue && t('no_options')}
						loadOptions={debounce(loadFootballConnectionsOnType, 300)}
						onChange={modifyFootballConnections}
						value={footballConnectionResponseToOptions(filteredFootballDataAsModels)}
						formatOptionLabel={(option) =>
							generateCustomOptionWithImage(
								option.label,
								option.value,
								option.data.url_thumb || option.data.url_logo || option.data.url_image,
								option.data.entity_type || '',
							)
						}
						components={{
							Option: SportCustomOption,
						}}
					/>
				</FormGroup>
			</Col>
		</Row>
	);
};

function mapStateToProps(state: any) {
	const nestedReduxPropertyName = extractRelatedPropertiesNameByUrl();

	return {
		reduxSportConnections: (nestedReduxPropertyName && state.tempSportsRelated[nestedReduxPropertyName]) || [],
	};
}

function mapDispatchToProps(dispatch: any) {
	const updateFootballConnectionRelatedAction = extractActionForUpdatingSportsConnections();

	return {
		updateSportsConnection: (rel: Related[]) => updateFootballConnectionRelatedAction && dispatch(updateFootballConnectionRelatedAction(rel)),
		clearSuggestedEntities: () => dispatch(clearSuggestedEntities()),
		removeSuggestedEntitiesByOrigin: (teamId: string) => dispatch(removeSuggestedEntitiesByOrigin(teamId)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(FootballConnectionsSelect);
