import { call, put, select, takeEvery } from 'redux-saga/effects';
import HttpService from '../../services/rest/HttpService';
import {
	VIDEO_CREATE,
	VIDEO_DELETE,
	VIDEO_REQUEST,
	VIDEO_SEARCH,
	VIDEO_UPDATE,
	videoCreateFailed,
	videoCreateSuccess,
	videoDeleteSuccess,
	videoReceived,
	videoRelatedCreateSuccess,
	videoRelatedReceived,
	videoRelatedUpdateSuccess,
	VIDEOS_REQUEST,
	videosReceived,
	videoUpdateFailed,
	videoUpdateSuccess,
	returnObjectForVideosContentStatisticsReceived,
	returnObjectForContentStatisticsVideoEntityReceived,
	returnObjectForVideoAlreadyExists,
	triggerVideoCreateResourcesSuccess,
} from '../action-creators/VideoActionCreator';
import { onError } from '../action-creators/GeneralActions';
import { toggleLoadingState, toggleModal, searchIsApplied, toggleContentTypeLoadingState } from '../action-creators/UiActionCreator';
import { actionService, featuresService, multiLingualService } from '../../App';
import { returnObjectForListEntityUpdate } from '../action-creators/ListActionCreators';
import List from '../../models/list/List';
import ListItem from '../../models/list/list-item/ListItem';
import { FeatureTypes } from '../../services/feature-service/features.enum';
import { extractIds } from '../../views/Resources/Articles/Helpers/ArticleHelper';
import { getSports, modifyContentResponseAsRelated, transferRefactoredWidgetsAsV2 } from './helpers/saga.helper';
import { ContentTypes } from '../../constants/content-types';
import { updateSidebarCustomEntitiesBulk } from '../action-creators/content-sidebar-tags';

function* fetchVideos(action: any) {
	yield put(toggleLoadingState(true));
	let videosResponse: any = {};

	try {
		let headers = { Project: action.payload.project.domain };
		const constructURL = action.payload.text && action.payload.text.length > 0 ? `/search?query=${action.payload.text}&` : '?';

		let contentLang = multiLingualService.checkIfProjectIsMultiLingual(action.payload.project.languages)
			? action.payload.project.languages.defaultLanguageCode.languageCode
			: action.payload.project.language;
		let contentLangQuery = `language=${contentLang}`;

		if (multiLingualService.checkIfProjectIsMultiLingual(action.payload.project.languages)) {
			videosResponse = yield call(HttpService.get, `/v2/videos${constructURL}${contentLangQuery}&page=${action.payload.page}`, null, headers);
		} else {
			videosResponse = yield call(HttpService.get, `/v2/videos${constructURL}page=${action.payload.page}`, null, headers);
		}

		yield put(videosReceived(videosResponse));
		yield put(toggleLoadingState(false));
	} catch (error) {
		yield put(onError(error));
	}

	try {
		if (
			featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_STATISTICS) &&
			videosResponse &&
			videosResponse.data &&
			videosResponse.data.data &&
			videosResponse.data.data.length > 0
		) {
			const contentStatisticsConfig = featuresService.getFeatureConfig(FeatureTypes.CONTENT_STATISTICS);
			const videosContentStatistics = yield call(
				HttpService.getContentStatistics,
				contentStatisticsConfig.request_headers[0],
				`${contentStatisticsConfig.url}?videos=${extractIds(videosResponse.data.data)}`,
			);
			yield put(returnObjectForVideosContentStatisticsReceived(videosResponse.videos, videosContentStatistics.data));
		}
	} catch (error) {
		yield put(onError(error));
	}
}

function* searchVideos(action: any) {
	yield put(toggleLoadingState(true));
	let videosResponse: any = {};

	try {
		let headers = { Project: action.payload.project.domain };
		let contentLang = multiLingualService.checkIfProjectIsMultiLingual(action.payload.project.languages)
			? action.payload.project.languages.defaultLanguageCode.languageCode
			: action.payload.project.language;
		let contentLangQuery = `&language=${contentLang}`;

		if (multiLingualService.checkIfProjectIsMultiLingual(action.payload.project.languages)) {
			videosResponse = yield call(HttpService.get, `/v2/videos/search?query=${action.payload.text}${contentLangQuery}`, null, headers);
		} else {
			videosResponse = yield call(HttpService.get, `/v2/videos/search?query=${action.payload.text}`, null, headers);
		}

		yield put(videosReceived(videosResponse));
		yield put(toggleLoadingState(false));
		yield put(searchIsApplied());
	} catch (error) {
		yield put(onError(error));
	}

	try {
		if (
			featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_STATISTICS) &&
			videosResponse &&
			videosResponse.data &&
			videosResponse.data.data &&
			videosResponse.data.data.length > 0
		) {
			const contentStatisticsConfig = featuresService.getFeatureConfig(FeatureTypes.CONTENT_STATISTICS);
			const videosContentStatistics = yield call(
				HttpService.getContentStatistics,
				contentStatisticsConfig.request_headers[0],
				`${contentStatisticsConfig.url}?videos=${extractIds(videosResponse.data.data)}`,
			);
			yield put(returnObjectForVideosContentStatisticsReceived(videosResponse.videos, videosContentStatistics.data));
		}
	} catch (error) {
		yield put(onError(error));
	}
}

function* postVideo(action: any) {
	let headers = { Project: action.payload.project.domain };
	let videoResponse: any = {};
	let videoId = null;

	try {
		let videoPayload = action.payload.video;
		videoResponse = yield call(HttpService.post, '/videos', videoPayload, headers);
		videoId = videoResponse.data.data.id;
		if (action.payload.list) {
			const listItems = action.payload.list.items.map((item: ListItem) => {
				if (!item.data.id) {
					return ListItem.builder(item)
						.withData({ ...item.data, id: videoId })
						.build();
				}
				return item;
			});
			const list = List.builder(action.payload.list).withItems(listItems).build();
			yield put(returnObjectForListEntityUpdate(list, action.payload.project));
		}
		yield put(videoCreateSuccess(videoId));
	} catch (error) {
		if (error.response.data.message.startsWith('A resource with the language code')) {
			yield put(returnObjectForVideoAlreadyExists());
		} else {
			yield put(videoCreateFailed());
		}
		actionService.emitError(error);
	}

	if (videoId) {
		try {
			let relatedPayload = action.payload.related;
			yield call(HttpService.post, `videos/${videoId}/related`, relatedPayload, headers);
			yield put(videoRelatedCreateSuccess(videoId));
			yield put(triggerVideoCreateResourcesSuccess(videoId));
		} catch (error) {
			yield put(onError(error));
			actionService.emitError(error);
		}
	}
}

function* fetchVideo(action: any) {
	yield put(toggleLoadingState(true));
	yield put(toggleContentTypeLoadingState(ContentTypes.VIDEO, true));
	let videoResponse: any = {};

	try {
		let headers = { Project: action.payload.project.domain };
		videoResponse = yield call(HttpService.get, `/videos/${action.payload.id}?optional_data=related_content`, null, headers);
		// format old video's refactored widgets as V2
		if (videoResponse && videoResponse.data && videoResponse.data.data && videoResponse.data.data.body) {
			videoResponse.data.data.body = transferRefactoredWidgetsAsV2(videoResponse.data.data.body);
		}

		// set video without related
		yield put(videoReceived(videoResponse));

		// set related data
		const videoRelated = modifyContentResponseAsRelated(videoResponse);
		const sports = yield select(getSports);
		yield put(updateSidebarCustomEntitiesBulk(videoRelated.data.data));
		yield put(videoRelatedReceived(videoRelated, sports));

		yield put(toggleLoadingState(false));
	} catch (error) {
		yield put(onError(error));
	}

	try {
		if (featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_STATISTICS)) {
			const videoId = videoResponse.data.data.id;
			const contentStatisticsConfig = featuresService.getFeatureConfig(FeatureTypes.CONTENT_STATISTICS);

			const videoContentStatistics = yield call(
				HttpService.getContentStatistics,
				contentStatisticsConfig.request_headers[0],
				`${contentStatisticsConfig.url}?videos=${videoId}`,
			);
			yield put(returnObjectForContentStatisticsVideoEntityReceived(videoResponse.videoEdit, videoContentStatistics.data));
		}
	} catch (error) {
		yield put(onError(error));
	}

	yield put(toggleContentTypeLoadingState(ContentTypes.VIDEO, false));
}

function* patchVideo(action: any) {
	let headers = { Project: action.payload.project.domain };
	let videoResponse: any = {};

	try {
		let videoPayload = action.payload.video;
		let id = action.payload.video.id;
		videoResponse = yield call(HttpService.patch, `/videos/${id}`, videoPayload, headers);
		yield put(videoReceived(videoResponse));
		yield put(videoUpdateSuccess());
	} catch (error) {
		yield put(videoUpdateFailed());
		yield put(onError(error));
		actionService.emitError(error);
	}

	try {
		let relatedPayload = action.payload.related;
		yield call(HttpService.post, `videos/${videoResponse.data.data.id}/related`, relatedPayload, headers);
		yield put(videoRelatedUpdateSuccess());
	} catch (error) {
		yield put(onError(error));
		actionService.emitError(error);
	}

	try {
		if (featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_STATISTICS)) {
			const videoId = videoResponse.data.data.id;
			const contentStatisticsConfig = featuresService.getFeatureConfig(FeatureTypes.CONTENT_STATISTICS);

			const videoContentStatistics = yield call(
				HttpService.getContentStatistics,
				contentStatisticsConfig.request_headers[0],
				`${contentStatisticsConfig.url}?videos=${videoId}`,
			);
			yield put(returnObjectForContentStatisticsVideoEntityReceived(videoResponse.videoEdit, videoContentStatistics.data));
		}
	} catch (error) {
		yield put(onError(error));
	}
}

function* deleteVideo(action: any) {
	try {
		let headers = { Project: action.payload.project.domain };
		let id = action.payload.id;
		yield call(HttpService.delete, `/videos/${id}`, null, headers);
		yield put(videoDeleteSuccess());
		yield put(toggleModal(false));
	} catch (error) {
		yield put(onError(error));
	}
}

function* videoSaga() {
	yield takeEvery(VIDEO_CREATE, postVideo);
	yield takeEvery(VIDEOS_REQUEST, fetchVideos);
	yield takeEvery(VIDEO_REQUEST, fetchVideo);
	yield takeEvery(VIDEO_SEARCH, searchVideos);
	yield takeEvery(VIDEO_UPDATE, patchVideo);
	yield takeEvery(VIDEO_DELETE, deleteVideo);
}

export default videoSaga;
