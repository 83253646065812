import { call, put, select, takeEvery } from 'redux-saga/effects';
import HttpService from '../../services/rest/HttpService';
import {
	WIKI_ENTITY_CREATE,
	WIKI_PAGES_ENTITY_DELETE,
	WIKI_ENTITY_REQUEST,
	WIKI_ENTITY_UPDATE,
	WIKI_PAGES_LISTING_REQUEST,
	WIKI_PAGES_SEARCH,
	returnObjectForWikiAlreadyExists,
	returnObjectForWikiLEntityCreateFailed,
	returnObjectForWikiLEntityCreateSuccess,
	returnObjectForWikiLEntityReceived,
	returnObjectForWikiLEntityRelatedCreateSuccess,
	returnObjectForWikiLEntityRelatedReceived,
	returnObjectForWikiLEntityRelatedUpdateSuccess,
	returnObjectForWikiLEntityUpdateFailed,
	returnObjectForWikiLEntityUpdateSuccess,
	returnObjectForWikiDeleteSuccess,
	returnObjectForWikiListingReceived,
	triggerWikiCreateResourcesSuccess,
} from '../action-creators/WikiActionCreator';
import { searchIsApplied, toggleContentTypeLoadingState, toggleLoadingState, toggleModal } from '../action-creators/UiActionCreator';
import { onError } from '../action-creators/GeneralActions';
import { actionService, multiLingualService } from '../../App';
import { returnObjectForListEntityUpdate } from '../action-creators/ListActionCreators';
import List from '../../models/list/List';
import ListItem from '../../models/list/list-item/ListItem';
import { getSports, modifyContentResponseAsRelated, transferRefactoredWidgetsAsV2 } from './helpers/saga.helper';
import { ContentTypes } from '../../constants/content-types';
import { updateSidebarCustomEntitiesBulk } from '../action-creators/content-sidebar-tags';

function* fetchWiki(action: any) {
	yield put(toggleLoadingState(true));
	yield put(toggleContentTypeLoadingState(ContentTypes.WIKI_PAGE, true));
	let wikiResponse: any = {};

	try {
		let headers = { Project: action.payload.project.domain };
		wikiResponse = yield call(HttpService.get, `/wiki-pages/${action.payload.id}?optional_data=related_content`, null, headers);
		// format old wiki's refactored widgets as V2
		if (wikiResponse && wikiResponse.data && wikiResponse.data.data && wikiResponse.data.data.body) {
			wikiResponse.data.data.body = transferRefactoredWidgetsAsV2(wikiResponse.data.data.body);
		}

		// set wiki without related
		yield put(returnObjectForWikiLEntityReceived(wikiResponse));

		// set related data
		const wikiRelated = modifyContentResponseAsRelated(wikiResponse);
		const sports = yield select(getSports);
		yield put(updateSidebarCustomEntitiesBulk(wikiRelated.data.data));
		yield put(returnObjectForWikiLEntityRelatedReceived(wikiRelated, sports));

		yield put(toggleLoadingState(false));
	} catch (error) {
		yield put(onError(error));
	}

	yield put(toggleContentTypeLoadingState(ContentTypes.WIKI_PAGE, false));
}

function* postWiki(action: any) {
	let headers = { Project: action.payload.project.domain };
	let wikiResponse: any = {};
	let wikiId = null;

	try {
		let wikiPayload = action.payload.wiki;
		wikiResponse = yield call(HttpService.post, '/wiki-pages', wikiPayload, headers);
		wikiId = wikiResponse.data.data.id;
		if (action.payload.list) {
			const listItems = action.payload.list.items.map((item: ListItem) => {
				if (item.data.id === 'temp-id') {
					return ListItem.builder(item)
						.withData({ ...item.data, id: wikiResponse.data.data.id })
						.build();
				}
				return item;
			});
			const list = List.builder(action.payload.list).withItems(listItems).build();
			yield put(returnObjectForListEntityUpdate(list, action.payload.project));
		}
		yield put(returnObjectForWikiLEntityCreateSuccess(wikiId));
	} catch (error) {
		if (error.response.data.message.startsWith('A resource with the language code')) {
			yield put(returnObjectForWikiAlreadyExists());
		} else {
			yield put(returnObjectForWikiLEntityCreateFailed());
		}
		actionService.emitError(error);
	}

	if (wikiId) {
		try {
			let relatedPayload = action.payload.related;
			yield call(HttpService.post, `wiki-pages/${wikiId}/related`, relatedPayload, headers);
			yield put(returnObjectForWikiLEntityRelatedCreateSuccess(wikiId));
			yield put(triggerWikiCreateResourcesSuccess(wikiId));
		} catch (error) {
			yield put(onError(error));
			actionService.emitError(error);
		}
	}
}

function* patchWiki(action: any) {
	let headers = { Project: action.payload.project.domain };
	let wikiResponse: any = {};

	try {
		let wikiPayload = action.payload.wiki;
		let id = action.payload.wiki.id;
		wikiResponse = yield call(HttpService.patch, `/wiki-pages/${id}`, wikiPayload, headers);
		yield put(returnObjectForWikiLEntityReceived(wikiResponse));
		yield put(returnObjectForWikiLEntityUpdateSuccess());
	} catch (error) {
		yield put(returnObjectForWikiLEntityUpdateFailed());
		yield put(onError(error));
		actionService.emitError(error);
	}

	try {
		let relatedPayload = action.payload.related;
		yield call(HttpService.post, `wiki-pages/${wikiResponse.data.data.id}/related`, relatedPayload, headers);
		yield put(returnObjectForWikiLEntityRelatedUpdateSuccess());
	} catch (error) {
		yield put(onError(error));
	}
}

function* fetchWikiPages(action: any) {
	yield put(toggleLoadingState(true));
	let wikiPagesResponse: any = {};

	try {
		let headers = { Project: action.payload.project.domain };
		let contentLang = multiLingualService.checkIfProjectIsMultiLingual(action.payload.project.languages)
			? action.payload.project.languages.defaultLanguageCode.languageCode
			: action.payload.project.language;
		let contentLangQuery = `language=${contentLang}`;
		const constructURL = action.payload.text && action.payload.text.length > 0 ? `/search?query=${action.payload.text}&` : '?';
		const selectedPage = action.payload.page ? action.payload.page : 1;

		if (multiLingualService.checkIfProjectIsMultiLingual(action.payload.project.languages)) {
			wikiPagesResponse = yield call(HttpService.get, `/wiki-pages${constructURL}${contentLangQuery}&page=${selectedPage}`, null, headers);
		} else {
			wikiPagesResponse = yield call(HttpService.get, `/wiki-pages${constructURL}page=${selectedPage}`, null, headers);
		}

		yield put(returnObjectForWikiListingReceived(wikiPagesResponse));
		yield put(toggleLoadingState(false));
	} catch (error) {
		yield put(onError(error));
	}
}

function* searchWikiPages(action: any) {
	yield put(toggleLoadingState(true));
	let wikiPagesResponse: any = {};

	try {
		let headers = { Project: action.payload.project.domain };
		let contentLang = multiLingualService.checkIfProjectIsMultiLingual(action.payload.project.languages)
			? action.payload.project.languages.defaultLanguageCode.languageCode
			: action.payload.project.language;
		let contentLangQuery = `&language=${contentLang}`;

		if (multiLingualService.checkIfProjectIsMultiLingual(action.payload.project.languages)) {
			wikiPagesResponse = yield call(HttpService.get, `/wiki-pages/search?query=${action.payload.text}${contentLangQuery}`, null, headers);
		} else {
			wikiPagesResponse = yield call(HttpService.get, `/wiki-pages/search?query=${action.payload.text}`, null, headers);
		}

		yield put(returnObjectForWikiListingReceived(wikiPagesResponse));
		yield put(toggleLoadingState(false));
		yield put(searchIsApplied());
	} catch (error) {
		yield put(onError(error));
	}
}

function* deleteWikiPage(action: any) {
	try {
		let headers = { Project: action.payload.project.domain };
		let id = action.payload.id;
		yield call(HttpService.delete, `/wiki-pages/${id}`, null, headers);
		yield put(returnObjectForWikiDeleteSuccess());
		yield put(toggleModal(false));
	} catch (error) {
		yield put(onError(error));
	}
}

function* wikiSaga() {
	yield takeEvery(WIKI_PAGES_LISTING_REQUEST, fetchWikiPages);
	yield takeEvery(WIKI_PAGES_SEARCH, searchWikiPages);
	yield takeEvery(WIKI_PAGES_ENTITY_DELETE, deleteWikiPage);
	yield takeEvery(WIKI_ENTITY_REQUEST, fetchWiki);
	yield takeEvery(WIKI_ENTITY_CREATE, postWiki);
	yield takeEvery(WIKI_ENTITY_UPDATE, patchWiki);
}

export default wikiSaga;
