import React from 'react';
import { relatedConstants, relatedConstantsTypes } from '../../../../../constants/related.constants';
import { SportTypes } from '../../../../../constants/sport.types';
import { generateDefaultImageByEntityType } from '../../../../../global-helpers/global.helpers';
import Related from '../../../../../models/related/Related';
import {
	updateArticleEditSportsTempRelated,
	updateArticleSportsTempRelated,
	updateGalleryEditSportsTempRelated,
	updateGallerySportsTempRelated,
	updateVideoEditSportsTempRelated,
	updateVideoSportsTempRelated,
	updateWikiEditSportsTempRelated,
	updateWikiSportsTempRelated,
} from '../../../../../store/action-creators/RelatedSportsTempActionCreator';
import { ReduxRelatedProperties } from '../constants/redux-related-properties';
import SportsTypesModel from '../../../../../models/v2/sports-types/sports-types.model';

export const extractProviderBySportType = (selectedSport: SportsTypesModel) => {
	switch (selectedSport.sport) {
		case SportTypes.FOOTBALL:
			return relatedConstants.providers.football;
		default:
			return relatedConstants.providers.sports;
	}
};

export const extractEventProviderBySportType = (selectedSport: SportTypes) => {
	switch (selectedSport) {
		case SportTypes.FOOTBALL:
			return relatedConstants.providers.football;
		default:
			return relatedConstants.providers.sports;
	}
};

// This method will return the nested property's name in redux store
export const extractRelatedPropertiesNameByUrl = (): string | null => {
	const currentUrl = window.location.hash;

	if (currentUrl.includes('/articles/edit')) {
		return ReduxRelatedProperties.articleEditRelated;
	} else if (currentUrl.includes('/articles/create')) {
		return ReduxRelatedProperties.articleRelated;
	} else if (currentUrl.includes('/videos/edit')) {
		return ReduxRelatedProperties.videoEditRelated;
	} else if (currentUrl.includes('/videos/create')) {
		return ReduxRelatedProperties.videoRelated;
	} else if (currentUrl.includes('/galleries/edit')) {
		return ReduxRelatedProperties.galleryEditRelated;
	} else if (currentUrl.includes('/galleries/create')) {
		return ReduxRelatedProperties.galleryRelated;
	} else if (currentUrl.includes('/wiki-pages/edit')) {
		return ReduxRelatedProperties.wikiEditRelated;
	} else if (currentUrl.includes('/wiki-pages/create')) {
		return ReduxRelatedProperties.wikiRelated;
	}

	return null;
};

export type FilterReduxRelatedPropertiesType = {
	filtered: Related[];
	rest: Related[];
} | null;

export const filterReduxRelatedProperties = (
	selectedSport: SportsTypesModel,
	reduxData: Related[] | null,
	allowedEntityTypes?: relatedConstantsTypes[],
): FilterReduxRelatedPropertiesType => {
	if (!reduxData || !selectedSport.sport) {
		return null;
	}

	const extractedProvider = extractProviderBySportType(selectedSport);
	const filterBySport =
		extractedProvider === relatedConstants.providers.sports ? (el: Related) => el.data.sport === selectedSport.sport : () => true;

	let predicate: Function = () => false;
	if (allowedEntityTypes) {
		predicate = (el: Related) => el.provider === extractedProvider && allowedEntityTypes.includes(el.type) && filterBySport(el);
	} else {
		predicate = (el: Related) => el.provider === extractedProvider && filterBySport(el);
	}

	// this reduce will return the filtered data and rest of the data as 2 separate arrays. For example:
	// If we selectedSport === football it should returns in the 'filtered' array data only from football.api;
	// the 'rest' array should contains the data for other providers and sports
	return reduxData.reduce(
		(res, item) => {
			res[predicate(item) ? 'filtered' : 'rest'].push(item);
			return res;
		},
		{ filtered: [] as Related[], rest: [] as Related[] },
	);
};

// The 2 methods below extracts the action that should be dispatched to update the football connection data
// Method 1
export const extractActionMethodForSportsConnections = (propertyName: string | null) => {
	switch (propertyName) {
		case ReduxRelatedProperties.articleEditRelated:
			return (relatedData: Related[]) => updateArticleEditSportsTempRelated(relatedData);
		case ReduxRelatedProperties.articleRelated:
			return (relatedData: Related[]) => updateArticleSportsTempRelated(relatedData);
		case ReduxRelatedProperties.videoEditRelated:
			return (relatedData: Related[]) => updateVideoEditSportsTempRelated(relatedData);
		case ReduxRelatedProperties.videoRelated:
			return (relatedData: Related[]) => updateVideoSportsTempRelated(relatedData);
		case ReduxRelatedProperties.galleryEditRelated:
			return (relatedData: Related[]) => updateGalleryEditSportsTempRelated(relatedData);
		case ReduxRelatedProperties.galleryRelated:
			return (relatedData: Related[]) => updateGallerySportsTempRelated(relatedData);
		case ReduxRelatedProperties.wikiEditRelated:
			return (relatedData: Related[]) => updateWikiEditSportsTempRelated(relatedData);
		case ReduxRelatedProperties.wikiRelated:
			return (relatedData: Related[]) => updateWikiSportsTempRelated(relatedData);
		default:
			return null;
	}
};

// Method 2
export const extractActionForUpdatingSportsConnections = () => {
	const nestedReduxPropertyName = extractRelatedPropertiesNameByUrl();
	return extractActionMethodForSportsConnections(nestedReduxPropertyName);
};

export const generateCustomOptionWithImage = (
	label: string | null,
	value: string | number | null,
	image: string | undefined,
	entityType: string,
) => {
	const defaultImageByEntityType = generateDefaultImageByEntityType(entityType);

	if (label && value) {
		return (
			<div id={`connections-drop-down-option-${value}`} className='option-container option-border'>
				<img
					className={`option-logo-${entityType}`}
					width={23}
					height={23}
					src={image || defaultImageByEntityType}
					onError={(i: any) => (i.target.src = defaultImageByEntityType)}
					alt={`option-logo-${entityType}`}
				/>
				<div>
					<div className='option-label'> {label} </div>
				</div>
			</div>
		);
	}

	return null;
};
