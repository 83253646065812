import React, { FunctionComponent } from 'react';
import Select from 'react-select';
import { ValueType } from 'react-select/src/types';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa';
import { availableSportsToOption, sportTypeToOption } from '../../helpers/sport-select.helper';
import SportsTypesModel from '../../../../../../models/v2/sports-types/sports-types.model';
import { useTranslation } from 'react-i18next';

type Properties = {
	selectedSport: SportsTypesModel | null;
	availableSports: SportsTypesModel[];
	setSelectedSport: Function;
};

const SportSelect: FunctionComponent<Properties> = ({ selectedSport, setSelectedSport, availableSports }) => {
	const { t } = useTranslation();

	if (!selectedSport) return null;

	const selectedSportAsOption = sportTypeToOption(selectedSport);
	const availableSportsAsOptions = availableSportsToOption(availableSports);
	const changeSport = (selectedSportOption: ValueType<any>) => setSelectedSport(selectedSportOption.data);

	return (
		<Row>
			<Col>
				<FormGroup>
					<Label htmlFor={DATA_QA_ATTRIBUTES.SPORT_SELECT}>{t('sport')}</Label>
					<Select
						id={DATA_QA_ATTRIBUTES.SPORT_SELECT}
						placeholder={t('select')}
						onChange={changeSport}
						value={selectedSportAsOption}
						options={availableSportsAsOptions}
					/>
				</FormGroup>
			</Col>
		</Row>
	);
};

export default SportSelect;
