import { extractCustomEntitiesFromRelatedApi } from '../../global-helpers/sidebar.helpers';
import { CustomEntitiesSelectOption, CustomEntityBasic } from '../../views/Pages/CustomEntities/models/models';
import {
	REMOVE_CONTENT_SIDEBAR_CUSTOM_ENTITIES,
	UPDATE_CONTENT_SIDEBAR_CUSTOM_ENTITIES,
	UPDATE_CONTENT_SIDEBAR_CUSTOM_ENTITIES_BULK,
	UPDATE_CONTENT_SIDEBAR_DOMAIN,
	UPDATE_CONTENT_SIDEBAR_ENTITY_TYPE,
} from '../action-creators/content-sidebar-tags';

export interface ICustomEntitiesReduxStore {
	[entityType: string]: CustomEntitiesSelectOption[];
}

interface InitialState {
	tags: {
		domain: CustomEntitiesSelectOption | null;
		entityType: string;
		customEntities: ICustomEntitiesReduxStore;
	};
}

const initialState: InitialState = {
	tags: {
		domain: null,
		entityType: '',
		customEntities: {},
	},
};

function contentSidebarReducer(state: any = initialState, action: any) {
	if (action.type === UPDATE_CONTENT_SIDEBAR_DOMAIN) {
		state = Object.assign({}, state, {
			tags: {
				...state.tags,
				domain: action.payload,
			},
		});
	}

	if (action.type === UPDATE_CONTENT_SIDEBAR_ENTITY_TYPE) {
		state = Object.assign({}, state, {
			tags: {
				...state.tags,
				entityType: action.payload,
			},
		});
	}

	if (action.type === UPDATE_CONTENT_SIDEBAR_CUSTOM_ENTITIES) {
		const { data, entityType } = action.payload;

		if (entityType) {
			state = Object.assign({}, state, {
				tags: {
					...state.tags,
					customEntities: {
						...state.tags.customEntities,
						[entityType]: [...data],
					},
				},
			});
		}
	}

	if (action.type === UPDATE_CONTENT_SIDEBAR_CUSTOM_ENTITIES_BULK) {
		const { bulkEntities } = action.payload;

		if (bulkEntities) {
			state = Object.assign({}, state, {
				tags: {
					...state.tags,
					customEntities: extractCustomEntitiesFromRelatedApi(bulkEntities),
				},
			});
		}
	}

	if (action.type === REMOVE_CONTENT_SIDEBAR_CUSTOM_ENTITIES) {
		const customEntity: CustomEntityBasic | null = action.payload;

		if (customEntity) {
			state = Object.assign({}, state, {
				tags: {
					...state.tags,
					customEntities: {
						...state.tags.customEntities,
						[customEntity.entity_type]: state.tags.customEntities[customEntity.entity_type].filter(
							(entity: CustomEntitiesSelectOption) => entity.value !== customEntity.id,
						),
					},
				},
			});
		}
	}

	return state;
}

export default contentSidebarReducer;
