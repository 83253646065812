import React from 'react';
import { Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Wiki from '../../../../models/wiki/Wiki';
import Project from '../../../../models/project/Project';
import Profile from '../../../../models/profile/Profile';
import Related from '../../../../models/related/Related';
import BlockModel from '../../../Partials/Blocky/models/block.model';
import Author from '../../../../models/author/Author';
import List from '../../../../models/list/List';
import { Subscription } from 'rxjs';
import { MandatoryFieldsService } from '../../../../services/mandatory-fields-service/mandatory-fields.service';
import { featuresService, multiLingualService, wordCountService } from '../../../../App';
import { FeatureTypes } from '../../../../services/feature-service/features.enum';
import {
	returnObjectForWikiEntityCreate,
	WIKI_CREATE_RESOURCES_SUCCESS,
	WIKI_ENTITY_CREATE,
	WIKI_ENTITY_CREATE_FAILED,
	WIKI_ENTITY_CREATE_SUCCESS,
} from '../../../../store/action-creators/WikiActionCreator';
import SeoModel from '../../../../models/seo/Seo';
import MainMedia from '../../../../models/mainmedia/MainMedia';
import { ContentMode, ContentTypes } from '../../../../constants/content-types';
import { toast } from 'react-toastify';
import Flag from 'react-world-flags';
import BlockableButtonWrapper from '../../../Partials/BaseComponents/BlockableButton/BlockableButtonWrapper';
import {
	IMAGE_UPLOAD_FAILED,
	IMAGE_UPLOAD_REQUEST,
	IMAGE_UPLOAD_SUCCESS,
	updateEditContentTempMediaDescription,
} from '../../../../store/action-creators/ImageActionCreator';
import { StrapLine } from '../../../Partials/Fields/strap-line/StrapLineComponent';
import WordCountComponent from '../../../Partials/Blocky/blocks/editor/subcomponents/word-count-component';
import { Title } from '../../../Partials/Fields/title/TitleComponent';
import { Summary } from '../../../Partials/Fields/summary/SummaryComponent';
import Blocky from '../../../Partials/Blocky/subcomponents/blocky.component';
import SidebarCollapsibleElements from '../../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-collapsible-elements';
import SidebarElementsToggle from '../../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-elements-toggle/sidebar-elements-toggle.component';
import EditorToolbar from '../../../Partials/EditorToolbar/EditorToolbar';
import { clearTempWiki, updateTempWiki } from '../../../../store/action-creators/WikiTempActionCreator';
import { updateWikiTagTempRelated } from '../../../../store/action-creators/RelatedTagsTempActionCreator';
import { updateWikiSportsTempRelated } from '../../../../store/action-creators/RelatedSportsTempActionCreator';
import { clearWikiMatchTempRelated, updateWikiMatchTempRelated } from '../../../../store/action-creators/RelatedMatchTempActionCreator';
import { updateWikiContentTempRelated } from '../../../../store/action-creators/RelatedContentTempActionCreator';
import { initializeBlockyUndoRedo, resetUndoRedoBlocky } from '../../../../store/action-creators/BlockyActionCreator';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
	createListenerForContentCreateRedirect,
	createStartingBlocky,
	createStartingEntity,
	populateExistingEntity,
} from '../../../../services/content-models-service/ContentModelService';
import {
	ContentSidebarChangeCollapseStatus,
	onChangeCollapseStatus,
	onSidebarElemSort,
	toggleSidebarEditMode,
} from '../../../../services/content-sidebar/content-sidebar.helper';
import { FormattedSidebarSectionValue } from '../../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.types';
import GlobalModal from '../../../Partials/Modals/Global/global-modal';
import {
	appendBeforeUnloadResetTempSidebar,
	defaultTempSidebarFromApiResponse,
	overwriteTempSidebarByContentType,
	saveSidebarSettingsByContentType,
} from '../../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.helper';
import SidebarTags from '../../../Partials/Sidebar/tags-refactored/tags';
import SidebarGeneralContent from '../../../Partials/Sidebar/general-content/GeneralContentAttributes';
import SidebarSEO from '../../../Partials/Sidebar/seo-refactored/seo.component';
import SidebarCustomData from '../../../Partials/Sidebar/custom-data/custom-data.component';
import { renderComponentOnlyIfFeatureEnabled } from '../../../../global-helpers/global-features.helpers';
import SidebarContentFooter from '../../../Partials/Sidebar/content-footer-refactored/content-footer.component';
import SidebarRelatedContent from '../../../Partials/Sidebar/related-content-refactored/related-content';
import SidebarMainMedia from '../../../Partials/Sidebar/media-refactored/media-wrapper';
import { clearSuggestedEntities } from '../../../../store/action-creators/suggested-entities';
import { apiFormatCustomEntitiesConnections, mergeRelatedEntities } from '../../../../global-helpers/sidebar.helpers';

export type Properties = {
	tReady: boolean;
	t: any;
	i18n: any;
	wiki: Wiki;
	updateTempWiki: any;
	updateTempMediaDescription: any;
	history: any;
	clearTempWiki: any;
	postWiki: any;
	project: Project;
	profile: Profile;
	relatedContent: Related[];
	relatedSports: Related[];
	relatedTags: Related[];
	relatedMatches: Related[];
	relatedCustomEntities: Related[];
	updateTagTempRelated: (arg: Related[]) => any;
	updateSportsTempRelated: (arg: Related[]) => any;
	updateRelatedContentTemp: (arg: Related[]) => any;
	updateMatchTempRelated: (arg: Related[]) => any;
	clearMatchTempRelated: () => any;
	initializeBlockyUndoRedo: (blocks: BlockModel[]) => any;
	resetUndoRedoBlocky: () => any;
	clearSuggestedEntities: () => void;
	authors: Author[];
	list: List | null;
	updateMandatoryFields: (fields: any) => any;
	wordCount: any;
	charCount: any;
};

export type State = {
	toggleDeleteModal: boolean;
	isSidebarInEdit: boolean;
	areMandatoryFieldsFilled: boolean;
	totalWordCount: number;
	totalCharCount: number;
	addWatermarkToImageMedia: boolean;
	openSidebarSettingsModalFlag: boolean;
};

class WikiCreate extends React.Component<Properties, State> {
	private contentValidation: MandatoryFieldsService = new MandatoryFieldsService();
	private actionServiceSubject = new Subscription();

	constructor(props: Properties) {
		super(props);
		this.state = {
			toggleDeleteModal: false,
			isSidebarInEdit: false,
			areMandatoryFieldsFilled: true,
			totalWordCount: 0,
			totalCharCount: 0,
			addWatermarkToImageMedia: false,
			openSidebarSettingsModalFlag: false,
		};
	}

	componentDidMount(): void {
		this.initPageTitle();
		this.initWikiCreateSuccessListener();
		this.displayClearTempWikiModal();
		appendBeforeUnloadResetTempSidebar(ContentTypes.WIKI_PAGE);

		this.props.wiki.id === 'temp-copy-id' && this.props.updateTempWiki(Wiki.builder(this.props.wiki).withId('temp-id').build());

		let wiki = populateExistingEntity(ContentTypes.WIKI_PAGE, this.props.wiki);
		if (wiki && (!wiki.body || wiki.body.length === 0)) {
			wiki = populateExistingEntity(ContentTypes.WIKI_PAGE, this.props.wiki, true);
		}

		this.props.updateTempWiki({ ...wiki, properties: Wiki.extractDynamicPropertiesAsObjectWithValues() });
		try {
			this.props.wiki.body && this.props.wiki.body.length > 0 && this.props.initializeBlockyUndoRedo([...this.props.wiki.body]);
		} catch {
			this.props.initializeBlockyUndoRedo(createStartingBlocky());
		}
	}

	componentWillUnmount() {
		this.actionServiceSubject.unsubscribe();
		this.props.resetUndoRedoBlocky();
	}

	componentDidUpdate(prevProps: Properties, prevState: State) {
		if (prevProps !== this.props && featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.WORDCOUNT)) {
			this.calculateTotalWordCharCount();
		}

		if (this.props.project !== prevProps.project) {
			if (multiLingualService.checkIfProjectIsMultiLingual(this.props.project.languages)) {
				const wiki = populateExistingEntity(ContentTypes.WIKI_PAGE, this.props.wiki);
				wiki && this.props.updateTempWiki(wiki);
			}
		}
	}

	calculateTotalWordCharCount() {
		let totalWordCharCount = wordCountService.calculateTotalWordCharCount(this.props.wiki.body);
		this.setState(totalWordCharCount);
	}

	// TODO Move this logic to a store variable in a given reducer ( in this case wiki-temp.reducer)
	displayClearTempWikiModal() {
		if (
			(!Wiki.builder(this.props.wiki).isEmpty() ||
				(this.props.relatedContent && this.props.relatedContent.length > 0) ||
				(this.props.relatedTags && this.props.relatedTags.length > 0) ||
				(this.props.relatedMatches && Object.entries(this.props.relatedMatches).length > 0) ||
				(this.props.relatedSports && this.props.relatedSports.length > 0)) &&
			this.props.wiki.id !== 'temp-copy-id'
		) {
			this.toggleModal(true);
		}
	}

	initPageTitle() {
		document.title = this.props.t('wiki_page_create');
	}

	initWikiCreateSuccessListener() {
		createListenerForContentCreateRedirect(this.props.history, WIKI_CREATE_RESOURCES_SUCCESS, 'wiki-pages');
	}

	onTitleChange(title: string) {
		const seo = SeoModel.builder(this.props.wiki.seo).withAutoUpdateSeo(title).build();
		const wiki = Wiki.builder(this.props.wiki).withTitle(title).withSeo(seo).build();
		this.props.updateTempWiki(wiki);
	}

	onStrapLineChange(strapline: string) {
		const wiki = Wiki.builder(this.props.wiki).withStrapline(strapline).build();
		this.props.updateTempWiki(wiki);
	}

	onSummaryChange(summary: string) {
		const wiki = Wiki.builder(this.props.wiki).withSubtitle(summary).build();
		this.props.updateTempWiki(wiki);
	}

	onBodyChange = (blocks: any) => {
		const wiki = Wiki.builder(this.props.wiki).withBody(blocks).build();
		this.props.updateTempWiki(wiki);
	};

	toggleModal(display: boolean) {
		this.setState({
			...this.state,
			toggleDeleteModal: display,
		});
	}

	onSubmit() {
		const { wiki } = this.props;
		const relatedList = mergeRelatedEntities(this.props);

		if (wiki.mainMedia && wiki.mainMedia[0] && wiki.mainMedia[0].addDescriptionToMedia) {
			this.props.updateTempMediaDescription(wiki.mainMedia, this.props.project);
		}

		if (featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.MANDATORY_FIELDS)) {
			if (this.contentValidation.validateByContentType(this.props.wiki, ContentTypes.WIKI_PAGE)) {
				this.checkForWatermarkChecked(relatedList);
				this.updateMandatoryFieldsFilled(true);
			} else {
				this.updateMandatoryFieldsFilled(false);
			}
		} else {
			this.checkForWatermarkChecked(relatedList);
		}
	}

	checkForWatermarkChecked = (relatedList: any) => {
		if (
			//If apply watermark is checked, but no main media image is selected, saving is blocked.
			this.props.wiki.mainMedia &&
			this.props.wiki.mainMedia[0] &&
			this.props.wiki.mainMedia[0].addWatermarkToImageMedia &&
			!this.props.wiki.mainMedia[0].resourceId
		) {
			toast.error(this.props.t('missing_main_image_watermark'));
		} else {
			this.props.postWiki(this.props.wiki, JSON.parse(JSON.stringify(relatedList)), this.props.project, this.props.list);
			try {
				this.props.wiki.body && this.props.wiki.body.length > 0 && this.props.initializeBlockyUndoRedo([...this.props.wiki.body]);
			} catch {
				this.props.initializeBlockyUndoRedo(createStartingBlocky());
			}
		}
	};

	updateMandatoryFieldsFilled = (areFilledFields: boolean) => {
		this.setState({ ...this.state, areMandatoryFieldsFilled: areFilledFields });
	};

	toggleSidebarEditMode = () => {
		const newState = toggleSidebarEditMode({ ...this.state }, ContentTypes.WIKI_PAGE);
		this.setState(newState);
	};

	render() {
		const { t, project, wiki } = this.props;
		const { isSidebarInEdit, openSidebarSettingsModalFlag } = this.state;
		const langCode = multiLingualService.getProjectLanguage();

		const sidebarComponentsMap = {
			general: <SidebarGeneralContent t={t} />,
			media: (
				<SidebarMainMedia
					t={t}
					areMandatoryFieldsFilled={this.state.areMandatoryFieldsFilled}
					updateMandatoryFieldsFilled={this.updateMandatoryFieldsFilled}
				/>
			),
			content_footer: renderComponentOnlyIfFeatureEnabled(FeatureTypes.CONTENT_FOOTER, <SidebarContentFooter t={t} />),
			tags: <SidebarTags />,
			related_content: <SidebarRelatedContent t={t} />,
			seo: <SidebarSEO t={t} />,
			custom_data: <SidebarCustomData t={t} />,
		};

		const saveBlockOnActions = [WIKI_ENTITY_CREATE, IMAGE_UPLOAD_REQUEST];
		const saveReleaseOnActions = [WIKI_ENTITY_CREATE_SUCCESS, WIKI_ENTITY_CREATE_FAILED, IMAGE_UPLOAD_SUCCESS, IMAGE_UPLOAD_FAILED];

		return (
			<div className='animated fadeIn'>
				<Row>
					<Col col='8' lg='8' md='12' sm='12' xs='12'>
						<div className='card modified-card-container'>
							<div className='card-header'>
								<div className={'d-flex align-items-center'}>
									{multiLingualService.checkIfProjectIsMultiLingual(project.languages) && (
										<div className='mr-2 d-flex'>
											<Flag
												code={wiki && wiki.language === 'en' ? 'gb' : wiki && wiki.language}
												width='20'
												fallback={<i className='fa fa-flag ml-2' />}
											/>
										</div>
									)}
									<span className={'mr-auto mb-0'}>{t('new_wiki')}</span>
									<BlockableButtonWrapper blockOnActions={saveBlockOnActions} releaseOnActions={saveReleaseOnActions}>
										<Button size='sm' color='primary' id='wiki-create-save-top' className={'ml-auto mr-2'} onClick={this.onSubmit.bind(this)}>
											<i className='fa fa-floppy-o'></i> {t('save_wiki')}
										</Button>
									</BlockableButtonWrapper>
								</div>
							</div>
							<div className='card-body'>
								<Row>
									<Col xs='12'>
										<Label htmlFor='title'>{t('title')}</Label>
										<Row>
											<Col>
												<StrapLine t={t} onChange={this.onStrapLineChange.bind(this)} value={this.props.wiki.strapline} />
											</Col>
											<Col>
												<WordCountComponent t={t} wordCount={this.state.totalWordCount} charCount={this.state.totalCharCount} />
											</Col>
										</Row>
										<Title t={t} onChange={this.onTitleChange.bind(this)} value={this.props.wiki.title} dataQaProperty={'default-title'} />
									</Col>
								</Row>
								<Row>
									<Col xs='12'>
										<Summary t={t} onChange={this.onSummaryChange.bind(this)} showValidation={true} value={this.props.wiki.subtitle} />
									</Col>
								</Row>
								<Row className={'mb-3'}>
									<Col xs='12'>
										<FormGroup>
											<Label htmlFor='summary'>{t('body')}</Label>
											<Blocky
												t={t}
												contentType={ContentTypes.WIKI_PAGE}
												contentLanguage={multiLingualService.checkIfProjectIsMultiLingual(project.languages) ? wiki.language || langCode : langCode}
												entityType={
													multiLingualService.checkIfProjectIsMultiLingual(project.languages) && wiki.translationGroup
														? wiki.translationGroup.contentType
														: 'wiki'
												}
												blocks={wiki.body ? wiki.body : []}
												onChange={this.onBodyChange}
											/>
										</FormGroup>
									</Col>
								</Row>
								<Row className={'mb-3'}>
									<Col>
										<BlockableButtonWrapper blockOnActions={saveBlockOnActions} releaseOnActions={saveReleaseOnActions}>
											<Button
												color={'primary'}
												id='wiki-create-save-bottom'
												className={'text-uppercase font-weight-bold mr-2'}
												onClick={this.onSubmit.bind(this)}
											>
												<i className='fa fa-floppy-o'></i> {t('save_wiki')}
											</Button>
										</BlockableButtonWrapper>
									</Col>
									<Col>
										<WordCountComponent t={t} wordCount={this.state.totalWordCount} charCount={this.state.totalCharCount} />
									</Col>
								</Row>
							</div>
						</div>
					</Col>
					<Col col='4' lg='4' md='12' sm='12' xs='12' className='position-relative'>
						<SidebarElementsToggle t={t} toggleSidebarEditMode={this.toggleSidebarEditMode} isSidebarInEdit={isSidebarInEdit} />
						<SidebarCollapsibleElements
							isSidebarInEdit={isSidebarInEdit}
							onElemSort={(settingsValue: FormattedSidebarSectionValue[]) => onSidebarElemSort(settingsValue, ContentTypes.WIKI_PAGE)}
							onChangeCollapseStatus={(data: ContentSidebarChangeCollapseStatus) => onChangeCollapseStatus(data)}
							contentType={ContentTypes.WIKI_PAGE}
							sidebarComponentsMap={sidebarComponentsMap}
							contentMode={ContentMode.CREATE}
							t={t}
						/>
					</Col>
				</Row>
				<Modal isOpen={this.state.toggleDeleteModal} className={'modal-info'}>
					<ModalHeader>{t('draft_detected_title')}</ModalHeader>
					<ModalBody>
						{t('draft_detected_body')}{' '}
						{multiLingualService.checkIfProjectIsMultiLingual(project.languages) && wiki.translationGroup && !wiki.translationGroup.id ? (
							<div>
								{t('draft_lang')}
								<strong>{t(wiki.language)}</strong>.
							</div>
						) : (
							''
						)}{' '}
					</ModalBody>
					<ModalFooter>
						<Button
							color='danger'
							className={'mr-auto'}
							onClick={() => {
								this.props.clearTempWiki();
								this.props.clearSuggestedEntities();

								if (this.props.project.languages.defaultLanguageCode.languageCode) {
									const wiki = createStartingEntity(ContentTypes.WIKI_PAGE);
									wiki && this.props.updateTempWiki(wiki);
									wiki && this.props.initializeBlockyUndoRedo([...wiki.body]);
								}

								this.toggleModal(false);
							}}
						>
							{t('draft_detected_delete')}
						</Button>{' '}
						<Button
							color='info'
							onClick={() => {
								this.toggleModal(false);
							}}
							className={'text-light'}
						>
							{t('draft_detected_continue')}
						</Button>
					</ModalFooter>
				</Modal>
				<EditorToolbar
					contentType={ContentTypes.WIKI_PAGE}
					onChange={this.onBodyChange}
					save={{
						invokeFunction: this.onSubmit.bind(this),
						blockOnActions: saveBlockOnActions,
						releaseOnActions: saveReleaseOnActions,
					}}
					onPreview={null}
					onCopyToProject={null}
					onAuditLog={null}
					t={t}
				/>
				<GlobalModal
					isOpen={openSidebarSettingsModalFlag}
					t={this.props.t}
					headerContent='save_sidebar_settings'
					bodyContent='unsaved_changes'
					submitFunction={() => saveSidebarSettingsByContentType(ContentTypes.WIKI_PAGE, t)}
					beforeCloseFunction={() =>
						overwriteTempSidebarByContentType(ContentTypes.WIKI_PAGE, defaultTempSidebarFromApiResponse[ContentTypes.WIKI_PAGE].value)
					}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		wiki: state.tempWiki.wiki,
		project: state.project.currentProject,
		profile: state.profile.profile,
		relatedContent: state.tempContentRelated.wikiRelated,
		relatedSports: state.tempSportsRelated.wikiRelated,
		relatedTags: state.tempTagsRelated.wikiRelated,
		relatedMatches: state.tempMatchRelated.wikiRelated,
		list: state.list.contentCreateList,
		wordCount: state.blocky.wordCount,
		charCount: state.blocky.charCount,
		relatedCustomEntities: apiFormatCustomEntitiesConnections(state.contentSidebar.tags.customEntities),
	};
}

function mapDispatchToProps(dispatch: any) {
	const isUndoRedoEnabled =
		featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.CONTENT_BLOCKY_UNDO_REDO) &&
		featuresService.checkAvailableUndoRedoPageFromFeature(ContentTypes.WIKI_PAGE);
	return {
		updateTempWiki: (wiki: Wiki) => dispatch(updateTempWiki(wiki)),
		updateTempMediaDescription: (media: MainMedia, project: Project) => dispatch(updateEditContentTempMediaDescription(media, project)),
		clearTempWiki: () => dispatch(clearTempWiki()),
		postWiki: (wiki: Wiki, related: Related[], project: Project, list: null | List) =>
			dispatch(returnObjectForWikiEntityCreate(wiki, related, project, list)),
		updateTagTempRelated: (related: Related[]) => dispatch(updateWikiTagTempRelated(related)),
		updateSportsTempRelated: (related: Related[]) => dispatch(updateWikiSportsTempRelated(related)),
		updateMatchTempRelated: (related: Related[]) => dispatch(updateWikiMatchTempRelated(related)),
		updateRelatedContentTemp: (related: Related[]) => dispatch(updateWikiContentTempRelated(related)),
		clearMatchTempRelated: () => dispatch(clearWikiMatchTempRelated()),
		initializeBlockyUndoRedo: (blocks: BlockModel[]) => isUndoRedoEnabled && dispatch(initializeBlockyUndoRedo(blocks)),
		resetUndoRedoBlocky: () => isUndoRedoEnabled && dispatch(resetUndoRedoBlocky()),
		clearSuggestedEntities: () => dispatch(clearSuggestedEntities()),
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(WikiCreate) as React.ComponentType;
