import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { SportTypes, SportTypesValue } from '../../../../../../constants/sport.types';
import Related from '../../../../../../models/related/Related';
import ListRelatedContentContainer from '../../../../BaseComponents/list-related-content/list-related-content-container';
import { extractActionForUpdatingSelectedEvents, extractAlreadyAddedEvents } from '../../helpers/events.helper';
import { useTranslation } from 'react-i18next';
import SportsTypesModel from '../../../../../../models/v2/sports-types/sports-types.model';

type Properties = {
	selectedSport: SportsTypesModel | null;
	alreadyAddedEvents: Related[];
	restRelatedEvents: Related[];
	updateSelectedEvents: Function;
};

const SelectedEvents: FunctionComponent<Properties> = ({ alreadyAddedEvents, selectedSport, restRelatedEvents, updateSelectedEvents }) => {
	const { t } = useTranslation();

	if (!selectedSport || alreadyAddedEvents.length === 0) return null;

	const onRelatedContentRemove = (related: Related) => {
		const dataWithoutRemovedEl = alreadyAddedEvents.filter(
			(alreadyAddedEvent) =>
				alreadyAddedEvent.provider === related.provider &&
				alreadyAddedEvent.type === related.type &&
				alreadyAddedEvent.data.id !== related.data.id,
		);

		const result = restRelatedEvents.length > 0 ? [...restRelatedEvents, ...dataWithoutRemovedEl] : dataWithoutRemovedEl;
		updateSelectedEvents(result);
	};

	const onSortEnd = (sortedData: Related[]) => {
		const result = restRelatedEvents.length > 0 ? [...restRelatedEvents, ...sortedData] : [...sortedData];
		updateSelectedEvents(result);
	};

	return (
		<Row className='mb-4'>
			<Col>
				<ListRelatedContentContainer
					onRelatedContentRemove={onRelatedContentRemove}
					onSortEnd={onSortEnd}
					relatedContent={alreadyAddedEvents as Related[]}
					t={t}
					type={selectedSport.sport === SportTypes.FOOTBALL ? 'matches' : 'events'}
				/>
			</Col>
		</Row>
	);
};

function mapStateToProps(state: any, componentProps: { selectedSport: SportsTypesModel }) {
	const relatedDataFiltered = extractAlreadyAddedEvents(componentProps.selectedSport, state.tempMatchRelated);

	return {
		alreadyAddedEvents: relatedDataFiltered && relatedDataFiltered.filtered ? relatedDataFiltered.filtered : [],
		restRelatedEvents: relatedDataFiltered && relatedDataFiltered.rest ? relatedDataFiltered.rest : [],
	};
}

function mapDispatchToProps(dispatch: any) {
	const actionForUpdatingSelectedEvents = extractActionForUpdatingSelectedEvents();

	return {
		updateSelectedEvents: (related: Related[]) => actionForUpdatingSelectedEvents && dispatch(actionForUpdatingSelectedEvents(related)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectedEvents);
