export enum DATA_QA_ATTRIBUTES {
	TAGS_SELECT = 'sidebar-tags-tags-select',
	SPORT_SELECT = 'sidebar-tags-sport-select',
	FOOTBALL_CONNECTION_SELECT = 'sidebar-tags-football-connection-select',
	FOOTBALL_CONNECTION_TOURNAMENT_SELECT = 'sidebar-tags-football-connection-tournament-select',
	FOOTBALL_CONNECTION_TOURNAMENT_SEASON_SELECT = 'sidebar-tags-football-connection-tournament-season-select',
	SPORT_CONNECTION_SELECT = 'sidebar-tags-sport-connection-select',
	TOURNAMENT_FILTER_SELECT = 'sidebar-tags-tournament-filter-select',
	TOURNAMENT_SEASON_FILTER_SELECT = 'sidebar-tags-tournament-season-filter-select',
	DATE_FROM_FILTER_SELECT = 'sidebar-tags-date-from-filter-select',
	DATE_TO_FILTER_SELECT = 'sidebar-tags-date-to-filter-select',
	DOMAIN_SELECT = 'sidebar-tags-domain-select',
	ENTITY_TYPE_SELECT = 'sidebar-tags-entity-type-select',
	CUSTOM_ENTITY_SELECT = 'sidebar-tags-custom-entity-select',
	CUSTOM_ENTITY_CONNECTIONS_ORGANIZATION = 'sidebar-tags-custom-entity-organization-connections',
	CUSTOM_ENTITY_CONNECTIONS_PERSON = 'sidebar-tags-custom-entity-person-connections',
	CUSTOM_ENTITY_CONNECTIONS_PLACE = 'sidebar-tags-custom-entity-place-connections',
	CUSTOM_ENTITY_CONNECTIONS_ROLE = 'sidebar-tags-custom-entity-role-connections',
}
