import React, { FunctionComponent, useCallback } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { DATA_QA_ATTRIBUTES } from '../../constants/data-qa';
import AsyncSelect from 'react-select/async';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import CustomEntitiesHttpService from '../../../../../../services/rest/custom-entities.http.service';
import { CustomEntitiesSelectOption, CustomEntityBasic } from '../../../../../Pages/CustomEntities/models/models';
import {
	customConnectionsToOptions,
	CustomEntitiesOption,
	generateCustomOptionInfoDataByEntityType,
} from '../../../../../../global-helpers/sidebar.helpers';
import { generateCustomOptionWithImage } from '../../helpers/utils';
import { removeSidebarCustomEntity, updateSidebarCustomEntities } from '../../../../../../store/action-creators/content-sidebar-tags';
import { customConnectionColorStyles } from '../../constants/styles';
import { generateDefaultImageByEntityType } from '../../../../../../global-helpers/global.helpers';

type Properties = {
	domain: CustomEntitiesSelectOption | null;
	entityType: string;
	isSportDomainYN: boolean;
	updateSidebarCustomEntitiesRedux: (customEntities: CustomEntitiesSelectOption[], entityType: string) => void;
	removeSidebarCustomEntityRedux: (customEntity: CustomEntitiesSelectOption) => void;
	addedCustomEntitiesRedux: CustomEntitiesSelectOption[];
	customSelectValue?: {
		value: CustomEntitiesSelectOption[];
		label: string;
		dataQa: string;
		emptyLoadAction: boolean;
		entityType: string;
	};
};

const CustomEntitiesSelect: FunctionComponent<Properties> = ({
	domain,
	entityType,
	isSportDomainYN,
	updateSidebarCustomEntitiesRedux,
	removeSidebarCustomEntityRedux,
	addedCustomEntitiesRedux,
	customSelectValue,
}) => {
	const { t } = useTranslation();

	const debouncedLoadOptions = useCallback(
		debounce((inputValue: string, callback: (options: any[]) => void) => {
			loadConnectionsOnType(inputValue).then(callback);
		}, 300),
		[domain, entityType, addedCustomEntitiesRedux, customSelectValue],
	);

	if (isSportDomainYN || !domain) return null;

	const modifyConnections = (action: { action: string; removedValue?: CustomEntitiesSelectOption; option?: CustomEntitiesSelectOption }) => {
		switch (action.action) {
			case 'clear':
				customSelectValue
					? updateSidebarCustomEntitiesRedux([], customSelectValue.entityType)
					: updateSidebarCustomEntitiesRedux([], entityType);
				break;
			case 'remove-value':
				action.removedValue && removeSidebarCustomEntityRedux(action.removedValue);
				break;
			case 'select-option':
				action.option && updateSidebarCustomEntitiesRedux([...addedCustomEntitiesRedux, action.option], entityType);
				break;
		}
	};

	const loadConnectionsOnType = async (input: string) => {
		if ((customSelectValue && customSelectValue.emptyLoadAction) || input.length < 2) {
			return Promise.resolve([]);
		}

		try {
			const response = await CustomEntitiesHttpService.getDomainEntities(
				domain.data!!,
				customSelectValue ? customSelectValue.entityType : entityType,
				input,
				30,
			);

			const data = await response.data.results;
			const filteredData = data.filter(
				(entity: CustomEntityBasic) => !addedCustomEntitiesRedux.some((addedEl) => addedEl.value === entity.id),
			);

			const customOptions = customConnectionsToOptions(filteredData);

			if (!customOptions) {
				return [];
			}

			return customOptions.map((option) => ({
				...option, // Keep all original data
				label: option.name, // Add label property that react-select needs
			}));
		} catch (error) {
			console.error('Error fetching domain entities:', error);
			return [];
		}
	};

	const loadOptions = (inputValue: string) => {
		return new Promise((resolve) => {
			debouncedLoadOptions(inputValue, resolve);
		});
	};

	return (
		<Row>
			<Col>
				<FormGroup>
					<Label htmlFor={customSelectValue ? customSelectValue.dataQa : DATA_QA_ATTRIBUTES.CUSTOM_ENTITY_SELECT}>
						{customSelectValue ? customSelectValue.label : t('custom_entity_select')}
					</Label>
					<AsyncSelect
						className='custom-entities-select-sidebar'
						id={customSelectValue ? customSelectValue.dataQa : DATA_QA_ATTRIBUTES.CUSTOM_ENTITY_SELECT}
						styles={customConnectionColorStyles}
						isMulti
						isClearable
						placeholder={t('search_and_select')}
						noOptionsMessage={(inputValue) => (customSelectValue ? t('cannot_search_custom_entities') : inputValue && t('no_options'))}
						loadOptions={loadOptions}
						onChange={(_, action) => modifyConnections(action)}
						value={customSelectValue ? customSelectValue.value : null}
						isSearchable={!customSelectValue}
						formatOptionLabel={(option) =>
							generateCustomOptionWithImage(
								option.name,
								option.value,
								(option.additional && option.additional.display_asset && option.additional.display_asset.url) || '',
								(option.additional && option.additional.entity_type) || '',
							)
						}
						components={{
							Option: CustomEntitiesOption,
						}}
					/>
				</FormGroup>
			</Col>
		</Row>
	);
};

function mapStateToProps(state: any) {
	const entityType = (state.contentSidebar.tags && state.contentSidebar.tags.entityType) || '';
	return {
		domain: (state.contentSidebar.tags && state.contentSidebar.tags.domain) || null,
		entityType,
		addedCustomEntitiesRedux:
			(state.contentSidebar.tags && state.contentSidebar.tags.customEntities && state.contentSidebar.tags.customEntities[entityType]) || [],
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		updateSidebarCustomEntitiesRedux: (customEntities: CustomEntitiesSelectOption[], entityType: string) =>
			dispatch(updateSidebarCustomEntities(customEntities, entityType)),
		removeSidebarCustomEntityRedux: (customEntity: CustomEntitiesSelectOption) => dispatch(removeSidebarCustomEntity(customEntity)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomEntitiesSelect);
